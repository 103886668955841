import { useMemo } from 'react';
import _ from "lodash";
import useSession from "src/modules/session/hooks/useSession";
import useCreateStakeholder from "src/modules/startup/onBoarding/core/graphql/mutations/useCreateStakeholder";
import useGetStakeholdersGroupCompaniesLazy from "src/modules/startup/onBoarding/core/graphql/queries/useGetStakeholdersGroupCompaniesLazy";
import useUpsertRole from 'src/modules/startup/Stakeholder/graphql/mutation/useUpsertRole';

const useServices = ({ initialValues }) => {
  const { startupId } = useSession();
  const { manualQuery: manualQueryStakeholdersGroupCompanies } = useGetStakeholdersGroupCompaniesLazy();

  const { createStakeholder } = useCreateStakeholder({
    showSuccessNotification: true,
    successMessage: 'STAKEHOLDER_ADD_SUCCESS'
  }, {});

  const { upsertComponentRole } = useUpsertRole({
    showSuccessNotification: false,
  }, {});

  return useMemo(() => {
    const initialData = async () => {
      const result = await manualQueryStakeholdersGroupCompanies();
      const stakeholders = _.get(result?.data, 'stakeholderList', []);
      return { ...initialValues, stakeholders };
    };

    const SaveStep = async (context, event) => {
      const isAnEntity = context?.AddDirector?.isCorporate === true ?? false;
      const data = event?.payload?.values;
      const legalEntityStructure = context?.LegalEntityStructure?.legalEntityStructure ?? null;
      let searchID = null;
      if (context?.AddDirector?.isCorporate) {
        searchID = _.find(context?.initialData?.stakeholders, (st) => st.id === context?.AddDirector?.stakeholderCorporate?.id);
      } else {
        searchID = _.find(context?.initialData?.stakeholders, (st) => st.id === context?.AddDirector?.stakeholderIndividual?.id);
      }
      const isUpdate = !_.isEmpty(searchID);
      if (isUpdate) {
        const variables = {
          startupId,
          stakeholderId: context?.AddDirector?.stakeholderIndividual?.id || context?.AddDirector?.stakeholderCorporate?.id,
          stakeholderRolesData: {
            roles: {
              entityId: initialValues?.stakeholderId,
              entityType: 'STAKEHOLDER',
              role: [
                {
                  type: 'DIRECTOR'
                }
              ]
            }
          }
        };
        return await upsertComponentRole({ variables });
      } else {
        const variables = {
          startupId,
          stakeholderData: {
            stakeholder: {
              fullName: data?.stakeholderIndividual?.fullName || data?.stakeholderCorporate?.fullName || context?.AddDirector?.stakeholderIndividual?.fullName || context?.AddDirector?.stakeholderCorporate?.fullName,
              email: data?.email,
              isAnEntity: isAnEntity,
              companyType: isAnEntity ? legalEntityStructure : null,
            },
            roles: {
              entityId: initialValues?.stakeholderId,
              entityType: 'STAKEHOLDER',
              role: [
                {
                  type: 'DIRECTOR'
                }
              ]
            }
          }
        };
        return await createStakeholder({ variables });
      }
    };

    return { initialData, SaveStep };
  }, [createStakeholder, initialValues, manualQueryStakeholdersGroupCompanies, startupId, upsertComponentRole]);
}

export default useServices;
