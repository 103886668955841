/* eslint-disable import/no-anonymous-default-export */

export default {
  RELEVANT_ACTIVITIES_LABELS: {
    ACTING_AS_HOLDING_COMPANY: "Acting as Holding Company",
    BANKING: "Banking",
    DISTRIBUTION_AND_SERVICE: "Distribution and Service Centre",
    FINANCING_AND_LEASING: "Financing and Leasing Business",
    SHIPPING: "Shipping",
    FUND_MANAGEMENT: "Fund Management",
    ACTING_AS_HEADQUARTERS: "Acting as Headquarters",
    INSURANCE: "Insurance",
    INTELLECTUAL_PROPERTY: "Intellectual Property",
    NONE_OF_ABOVE: "None of the above",
  },
  COMMON: {
    SUBTITLE: "Let’s renew your company",
    TITLE: "What happens next",
  },
  WHATHAPPENS: {
    STEP_1: {
      TOOLTIP_1: "It is important to complete the renewal process ahead of the 31st of December to avoid fines for the company.<br><br>If you do not wish to renew, we strongly recommend that you close your Cayman company with Clara’s support. Doing nothing may result in fines and penalties.",
      TOOLTIP_2: "Please note, if the company’s Authorised Share Capital amount is greater than {{{currency}}} {{{amount}}} <strong>additional fees might apply.</strong>",
      CONTENT_1: "Next steps",
      CONTENT_2: "To renew your company, you must first confirm the company details by completing the renewal tasks on the Dashboard and submit the information to Clara for review. If you need to make any changes to this information, you will be able to send a request when completing the tasks (please note that some changes may incur fees).",
      CONTENT_3: "Costs",
      CONTENT_4: "The renewal fee for your Cayman entity is <strong>{{currency}} {{amount}}</strong> .",
      CONTENT_5: "Or click ",
      CONTENT_6: "here",
      CONTENT_7: " if you want to close this company.",
      BTN_START: "Let’s start",
    },
  },
  CLOSEDOWNCOMPANY: {
    STEP_1: {
      SUBTITLE: "Close down {{{companyName}}}",
      TITLE: "Close down your company",
      TOOLTIP_1: "To close down a company, the directors will sign a board resolution approving the striking of the company from the registrar.",
      CONTENT_1: "The costs to close down your company will be <strong>{{{currency}}} {{{amount}}}</strong>.  You must pay the fee and submit your request to Clara. <br><br>A board resolution approving the company’s strike off will then be sent to all the directors for signature via Docusign. Please keep an eye on your email.",
      BTN_START: "Proceed with strike off",
    },
  },
  UPLOAD_ANNUAL_ACCOUNTS_MODAL: {
    STEP_1: {
      TITLE: "Upload financial  accounts",
      SUBTITLE: "Upload Financial  Accounts",
      TOOLTIP_1: "Startup TopCo accounts are often nil as they are not transacting and are acting as pure equity holding companies. If this is the case, you can download our blank template and re-upload it to complete this task.",
      CONTENT_1: "Please submit a completed copy of the company's Balance Sheet. You can download a template",
      CONTENT_2: "Please submit a completed copy of the company's Profit and Loss. You can download a template",
      FIELD_DOCUMENT: "Document",
    },
  },
  ECONOMIC_SUBSTANCE: {
    GENERIC: {
      SUBTITLE: "Review Economic Substance Notification"
    },
    COMPANY_DETAILS: {
      TITLE: "Review company details",
      DESCRIPTION: "Please review the details in the following screens to complete the economic substance and annual renewal process. By clicking Next, you confirm that the details are correct.",
      TOOLTIP_1: "To change your company name additional fees will apply.",
      TOOLTIP_2: "Economic Substance: All Cayman companies are required to make an annual statement about whether or not it is carrying out one or more of a defined list of relevant activities. The company is required to meet the economic substance test for this activity. The test varies depending of the relevant activity conducted. Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6925004-economic-substance-an-overview-of-requirements-in-the-cayman-islands\">here</a> to read more.",
      NAME_BANNER: "Costs to file this change start from <strong>{{currency}} {{price}}</strong>. This item will be added to your cart once the task is completed. To pay and submit it, go to the Dashboard and click \"View cart\"."
    },
    COMPANY_ADDRESS: {
      TITLE: "Review company mailing address",
      TOOLTIP: "This should be an address where your Company is able to receive mail. This should not be your Registered Agent address."
    },
    RELEVANT_ACTIVITIES: {
      TITLE: "Does the company carry on one or more of the following activities?",
      TOOLTIP_1: "For most exempted companies, the relevant activity is “Acting as a Holding Company”.<br/><br/>If you select anything other than “Acting as a Holding Company”, or select none of the options, you will be subject to a more strenuous economic substance test.",
      TOOLTIP_2: "Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6385789-what-is-a-relevant-activity-for-economic-substance-purposes\">here</a> to read definitions of each relevant activity.",
      TOOLTIP_3: "Click <a target=\"_blank\" href=\"https://help.clara.co/en/articles/6925004-economic-substance-an-overview-of-requirements-in-the-cayman-islands\">here</a> to read about Economic Substance.",
      TOOLTIP_4: "Please note that if you have selected only the option of “Acting as a Holding Company” and you then click Next, you confirm that the company meets the definition of a “Pure Equity Holding Company” as outlined in the Economic Substance Act for the period.",
      ACTIVITIES_LABEL: "Relevant activity (Select all that apply)",
      ACTIVITIES_BANNER: "Changing the activity will result in a more strenuous economic substance test. If you decide to proceed, Clara will review and be in touch with further details.",
    },
    TAX_INFORMATION: {
      TITLE: "Review economic substance information",
      TOOLTIP: 'Click <a target="_blank" href="https://help.clara.co/en/articles/8441344-economic-substance-tax-residency-outside-the-cayman-islands">here</a> to learn more about the tax residency outside the Cayman Islands. <br/><br/>' +
        'Click <a target="_blank" href="https://help.clara.co/en/articles/8441357-economic-substance-investment-funds">here</a> to learn more about investment funds.',
      OPTION_1: "Is the company tax resident outside of the Cayman Islands?",
      OPTION_2: "Is the company an Investment Fund (as defined in the Economic Substance Act)?",
      OPTION_3: "If the company carried on a relevant activity, was it a domestic company or local partnership (as defined in the Economic Substance Act)?",
      OPTION_4: "Does the company have an FI Number issued by the Cayman Islands Department for International Tax Cooperation?",
      OPTION_5: "Does the company have an IRS GIIN?",
    },
    COMPANY_INCOME: {
      TITLE: "Confirm company income",
      TOOLTIP_1: '<strong>Relevant income</strong> means all income related to the primary purpose of the company e.g. in the case of a holding company, this would be primarily dividends.',
      TOOLTIP_2: '<strong>Incidental income</strong> is any income not related to the primary purpose but incidental to it e.g. interest on cash deposited at banks',
      TOOLTIP_3: '<strong>Total income</strong> is equal to the sum of Total Relevant Income and Total Incidental Income',
    },
    DECLARATIONS: {
      TITLE: "Declarations",
      DESCRIPTION: 'Please read the Declarations carefully before clicking Confirm.',
      TERMS_1: 'All information in this Economic Substance Return is accurate;',
      TERMS_2: 'I acknowledge there are sanctions for knowingly or wilfully supplying false or misleading information under section 13(2) of the Economic Substance Act;',
      TERMS_3: 'I acknowledge that the Cayman Islands Tax Information Authority will verify details of any outsourcing with the service provider(s) specified;',
      TERMS_4: 'The Company has complied with all applicable filing requirements under the Companies Act;',
      TERMS_5: 'The Company has had adequate human resources in the Cayman Islands during the period for holding and managing equity participations in other entities; and',
      TERMS_6: 'The Company has had adequate premises in the Islands during the period for holding and managing equity participations in other entities.',
      TOOLTIP: 'Please read the following Declarations carefully and confirm them to complete the economic substance task.',
    }
  },
  IMMEDIATE_PARENTS: {
    GENERIC: {
      SUBTITLE: "Confirm immediate Parents ",
      TITLE3: "Edit immediate parent",
    },
    ADD_FORM: {
      TITLE: "Immediate parents ({{{count}}} of {{total}})",
      LABEL1: "Immediate parent name",
      LABEL2: "Mailing address type",
      LABEL3: "Mailing address",
      LABEL4: "Tax residence jurisdiction",
      LABEL5: "Tax Identification Number (TIN)",
      TOOLTIP: "An immediate parent is any individual or corporate shareholder that held 25% or more as at 31 December the previous calendar year (i.e. 31 December 2023 if we are in 2024).",
    }
  },
  ULTIMATE_PARENTS: {
    GENERIC: {
      SUBTITLE: "Confirm ultimate parent",
      TITLE: "Ultimate parent",
      TITLE2: "Add ultimate parent",
      TITLE3: "Edit the ultimate parent",
    },
    SELECTOR_FORM: {
      TEXT1: "Is your company indirectly controlled by an ultimate parent (e.g. through ownership of a majority of voting shares; or the ability to appoint a majority of directors; or through other means requiring this ultimate parent to consolidate the accounts of this company into its own accounts)?",
      TOOLTIP: "An ultimate parent can reside one or more layers above your direct shareholders, as long as they have “control”, as explained in the question.",
    },
    ADD_FORM: {
      LABEL1: "Ultimate parent name",
      LABEL2: "Mailing address type",
      LABEL3: "Mailing address",
      LABEL4: "Tax residence jurisdiction",
      LABEL5: "Tax Identification Number (TIN)",
    }
  },
  BENEFICIAL_OWNERS: {
    GENERIC: {
      SUBTITLE: "Confirm ultimate beneficial owners",
      TITLE: "Ultimate beneficial owners",
      TITLE2: "Add ultimate beneficial owners",
      TITLE3: "Edit ultimate beneficial owner",
    },
    CONFIRM_FORM: {
      TEXT1: "Is there any individual person who ultimately owns or controls, whether through direct or indirect ownership or control, 25% or more of the shares or voting rights in your company or has the right to appoint or remove a majority of the directors of your company?",
      TEXT2: "If no individual person meets the criteria above, is there any individual person who otherwise exercises or has the right to exercise significant influence or control over your company through its ownership structure or interests (other than as a director, professional advisor or manager)?",
      TOOLTIP: "An Ultimate Beneficial Owner is an individual person who ultimately owns or controls, whether through direct or indirect ownership or control, 25% or more of the shares or voting rights in your company or has the right to appoint or remove a majority of the directors of your company. If no individual person meets these criteria, any individual person who otherwise exercises or has the right to exercise significant influence or control over your company through its ownership structure or interests (other than as a director, professional advisor or manager) will be considered an Ultimate Beneficial Owner.",
    },
    ADD_FORM: {
      LABEL1: "Ultimate beneficial owner name",
      LABEL2: "Mailing address type",
      LABEL3: "Mailing address",
      LABEL4: "Tax residence jurisdiction",
      LABEL5: "Tax Identification Number (TIN)",
      LABEL6: "Type of ultimate beneficial ownership",
      TOOLTIP: "An Ultimate Beneficial Owner is an individual person who ultimately owns or controls, whether through direct or indirect ownership or control, 25% or more of the shares or voting rights in your company or has the right to appoint or remove a majority of the directors of your company. If no individual person meets these criteria, any individual person who otherwise exercises or has the right to exercise significant influence or control over your company through its ownership structure or interests (other than as a director, professional advisor or manager) will be considered an Ultimate Beneficial Owner.",
    },
    MORE_TO_ADD: {
      TITLE: "Do you have other ultimate beneficial owners?",
    }
  },
  CONFIRM_INFORMATION: {
    GENERIC: {
      SUBTITLE: "Confirm renewal information",
      TITLE: "Confirmation",
    },
    CONFIRM_FORM: {
      TEXT1: "Please read this carefully before clicking Confirm:",
      TEXT2: "I hereby confirm that all the information provided regarding the company is up to date time, accurate and complete, including with respect to directors, shareholders, authorised share capital and memorandum and articles of association.<span style='color: red'>*</span>",
    },
  }
}